[data-component='BenefitBarComponent'] {
  .benefit-bar-full{
    display: none;
    height: fit-content;
    padding: 4.8rem 2.4rem;
    max-height: 100%;
    overflow: auto;

    .benefit-loop-wrapper{
      flex-direction: column;
    }
  }
  .account-benefit-bar{
    display: none;
  }
}
