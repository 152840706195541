@keyframes scrollingtext {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

.bottom-dynamic-bar {
  position: fixed;
  bottom: 0;
  z-index: 15;

  .bottom-bar-item {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-width: 100vw;
    line-height: 0;

    &:hover .close-dynamic-bar {
      opacity: 1;
    }

    .close-dynamic-bar {
      position: absolute;
      bottom: 0.4rem;
      right: 0.8rem;
      background: $white;
      padding: 1.2rem 0.4rem 1.2rem 1rem;
      border-radius: 0.4rem;
      height: 0;
      display: flex;
      align-items: center;
      opacity: 0;
      transition: all 0.2s ease;
    }

    .wrapper-scrolling-text {
      height: 3.2rem;
      display: flex;
      position: relative;
      text-decoration: none;

      .scrolling-text {
        display: flex;
        align-items: center;
        box-sizing: content-box;
        font-size: 1.4rem;
        gap: 2.4rem;
        position: relative;
        white-space: nowrap;
        margin: 0;
        color: $white;
        padding-right: 2.4rem;
        animation: scrollingtext 15s linear -1s infinite forwards;

        span {
          width: 1.6rem;
          height: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}