[data-component='BenefitBarComponent'] {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  @include z(benefit-bar);
  background-color: $blue;
  display: flex;
  height: fit-content;
  transition: all 250ms ease-out;
  overflow: hidden;

  p {
    color: $white;
  }

  ul {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 0;
    list-style: none;
  }

  .benefit-bar-full {
    position: fixed;
    top: 0;
    width: 100vw;
    background: $blue;
    color: $white;
    overflow: hidden;
    padding: 8rem 14rem;
    display: none;
    text-transform: uppercase;

    .benefit-bar-child-full {
      flex: 1 0 0;
    }

    .js-close-benefit {
      position: absolute;
    }

    &.open+.overlay {
      opacity: 1;
      visibility: visible;
    }

    &+.overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 150vh;
      background: rgba(0, 0, 0, .3);
      @include z(neg);
      @include animate(0.3);
      opacity: 0;
      visibility: hidden;
    }
  }

  .benefit-bar-wrapper {
    height: 3.2rem;

    .benefit-bar-child-title span {
      white-space: nowrap;
    }

    li {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: fit-content;
      margin: 0;
      color: $white;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.2;
      opacity: 0;
      z-index: -1;
      transition: all 0.3s;
      pointer-events: none;

      &.active {
        opacity: 1;
        z-index: 1;
        transition: all 0.3s;
        pointer-events: initial;
      }

      a {
        margin: 0;
        color: $white;
        font-size: 1.2rem;
        text-decoration: none;
        line-height: unset;

        &::before {
          border-top: 0.1rem solid $white;
        }
      }
    }
  }

  &:hover,
  &:focus-within {
    button.icon.icon-chevron {
      opacity: 1;
      transition: all 0.3s;
    }
  }

  .icon--close-white {
    position: absolute;
    top: 2.6rem;
    right: 2.6rem;
    cursor: pointer;
  }

  .editmode & {
    ul li:first-child {
      opacity: 1;
      z-index: 1;
      transition: all 0.3s;
      pointer-events: initial;
    }
  }

  .account-benefit-bar {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 2;
    padding: 0;
    list-style: none;
    width: 50%;
    right: 0;

    .account-benefit-item {
      right: 2rem;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      margin: 0;
      padding: 0 2rem;
      color: $white;
      font-size: 1.4rem;
      line-height: 1.4rem;

      opacity: 0;
      z-index: -1;
      transition: all 0.3s ease-out;
      pointer-events: none;
      text-decoration: none;

      &.active {
        opacity: 1;
        z-index: 1;
        transition: all 0.3s;
        pointer-events: initial;
      }
    }
  }
}